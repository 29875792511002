import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hoppas att helgen har varit bra! Nedan så har vi laddat upp med dagens free spins erbjudande så att ni kan avnjuta resten av söndagen med ett par free spins.`}</p>
    <p>{`##Bertil – 300 free spins
Hos Bertil så kan man idag få 300 free spins. För att få dessa free spins så behöver man gå igenom tre steg. Första steget är att man ska göra en insättning på minst 400kr för att få 50 free spins, sedan i andra steget så ska man även där göra en insättning på minst 400kr och då får man 100 free spins och sedan i det sista steget så ska man göra en insättning på minst 400kr och då får man 150 free spins till. Totalt 300 free spins om man går igenom alla tre stegen.`}</p>
    <p>{`Få 300 free spins hos Bertil – Spela Nu!`}</p>
    <p>{`##Paf – Medaljbonus
Idag så bjuder PAF på en freeroll i Poker med $1000 i prispengar istället för free spins.
Har man inget konto sedan tidigare hos PAF så tar man emot 20 free spins på Starburst.`}</p>
    <p>{`Hämta 20 free spins på Starburst som ny kund – Spela Nu!`}</p>
    <p>{`##MamaMia – Casino bonus och free spins
Hos MamaMia så kan man idag få 2120kr i casino bonus och 50 free spins i Victorious. För att få dessa bonus pengar och free spins så behöver man gå igenom följande steg`}</p>
    <ul>
      <li parentName="ul">{`Sätter man in 111 kronor så får man 120 kr casino bonus.`}</li>
      <li parentName="ul">{`Sätter man in 444 kronor så får man 500 kronor bonus.`}</li>
      <li parentName="ul">{`Sätter man in 1222 kronor så får man 1500 kronor bonus.`}</li>
      <li parentName="ul">{`Sätter man in 2222 kronor så får man 50 free spins i Victorious .`}</li>
    </ul>
    <p>{`2120kr och 50 free spins hos MamaMia – Spela Nu!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      